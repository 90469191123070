import React from 'react';

function Footer() {
  return (
    <footer>
      <div>
        <p>
          본 사이트는 게임 <strong>"식스타 게이트: 스타트레일"</strong>의 팬 사이트이며,
          <br />
          사이트 내 사용된 모든 게임 컨텐츠의 저작권은 <strong>Lyrebird Studio</strong>에 있습니다.
        </p>
      </div>
      <div>
        <p>
          Special Thanks to <strong>
            <a href="https://twitter.com/suisou610" target="_blank" rel="noopener noreferrer">수조</a>
          </strong>
        </p>
      </div>
    </footer>
  )
}

export default React.memo(Footer);